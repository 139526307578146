import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Categories.module.css';
import { useLocation } from 'react-router-dom';
import Sidebar from '../Common/Sidebar/sidebar';

function Categories() {
  const [categories, setCategories] = useState([]);
  const [menu, setMenu] = useState(true);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [editCategoryName, setEditCategoryName] = useState('');
  const [editingCategory, setEditingCategory] = useState(null);
  const [creatingCategory, setCreatingCategory] = useState(false);

  // Fetch categories when the component mounts
  useEffect(() => {
    fetchCategories();
  }, []);

  const isLoggedIn = localStorage.getItem('token');
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await axios.post('https://api.sphereviewer.com.br/api/panel/logout', {}, {
        headers: {
          Authorization: `Bearer ${isLoggedIn}`,
        },
      });
      localStorage.removeItem('token');
      if (location.pathname !== '/') {
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${isLoggedIn}`,
    },
  };

  // Fetch categories from the server
  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://api.sphereviewer.com.br/api/panel/categories', config);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Create a new category
  const createCategory = async () => {
    if (newCategoryName === '') {
      return; // Evite chamadas duplicadas ou vazias
    }

    try {
      setCreatingCategory(true); // Marque a criação da categoria como em andamento
      const response = await axios.post('https://api.sphereviewer.com.br/api/panel/categories', { category_name: newCategoryName }, config);
      fetchCategories(); // Atualize a lista de categorias
    } catch (error) {
      console.error('Error creating category:', error);
    } finally {
      setNewCategoryName(''); // Limpe o input
      setCreatingCategory(false); // Marque a criação da categoria como concluída, bem-sucedida ou malsucedida
    }
  };

  // Update the name of a category
  const updateCategory = async (categoryId) => {
    if (editCategoryName === '') {
      return; // Evite chamadas duplicadas enquanto a requisição estiver em andamento
    }
    try {
      setEditingCategory(true); // Marque a atualização da categoria como em andamento
      const response = await axios.put(`https://api.sphereviewer.com.br/api/panel/categories/${categoryId}`, { category_name: editCategoryName }, config);
      fetchCategories(); // Atualize a lista de categorias
    } catch (error) {
      console.error('Error updating category:', error);
    } finally {
      setEditingCategory(false); // Marque a atualização da categoria como concluída, bem-sucedida ou malsucedida
      setEditCategoryName(''); // Limpe o input
    }
  };

  // Delete a category
  const deleteCategory = async (categoryId) => {
    try {
      await axios.delete(`https://api.sphereviewer.com.br/api/panel/categories/${categoryId}`, config);
      fetchCategories(); // Refresh the category list
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  return (
    <div className={styles.categories}>
      <Sidebar menu={menu} isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
      <div className={`${styles.content} ${menu ? styles.contentZ : styles.contentFull}`}>
        <h1>Minhas Categorias</h1>
        <div className={styles.creatingCategory}>
          <input
            type="text"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            placeholder="name da Categoria"
          />
          <button className={styles.creatingCategoryButton} onClick={createCategory} disabled={creatingCategory}>
            {creatingCategory ? 'Criando...' : 'Criar'}
          </button>
        </div>
        <ul>
          {categories.map((category) => (
            <li key={category.id} className={styles.categoryItem}>
              {editingCategory === category.id ? (
                <div className={styles.editCategoryNameSection}>
                  <input
                    type="text"
                    value={editCategoryName}
                    placeholder='Novo name'
                    onChange={(e) => setEditCategoryName(e.target.value)}
                  />
                  <button className={styles.editCategory} onClick={() => updateCategory(category.id)}>
                    Salvar
                  </button>
                </div>
              ) : (
                <div className={styles.nameSection}>name: {category.category_name}</div>
              )}
              <div>
                <button className={styles.editCategory} onClick={() => setEditingCategory(category.id)}>
                  Editar
                </button>
                <button className={styles.deleteCategory} onClick={() => deleteCategory(category.id)}>
                  Deletar
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Categories;