import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './meusDados.module.css';
import Sidebar from '../Common/Sidebar/sidebar';
import InputMask from 'react-input-mask';
import { useLocation } from 'react-router-dom';
import { FaSpinner, FaCopy, FaGlobe, FaLock, FaEnvelope, FaStar, FaCalendarAlt, FaUser, FaPhone } from 'react-icons/fa';

function MeusDados() {
  const [user, setUser] = useState({});
  const [menu, setMenu] = useState(true);
  const [message, setMessage] = useState(null);
  const [userData, setUserData] = useState(null);
  const [name, setname] = useState('');
  const [lastname, setlastname] = useState('');
  const [telephone, settelephone] = useState('');
  const [loadingField, setLoadingField] = useState(null);
  const [domain, setDomain] = useState('');
  const [token, setToken] = useState('');
  const [msgToken, setMsgToken] = useState('');

  const isLoggedIn = localStorage.getItem('token');
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await axios.post('https://api.sphereviewer.com.br/api/panel/logout', {}, {
        headers: {
          Authorization: `Bearer ${isLoggedIn}`,
        },
      });
      localStorage.removeItem('token');
      if (location.pathname !== '/') {
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Erro durante o logout:', error);
    }
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${isLoggedIn}`,
    },
  };

  const handleEdit = async (field) => {
    setLoadingField(field);
    let value;
    switch (field) {
      case 'name':
        value = name;
        break;
      case 'lastname':
        value = lastname;
        break;
      case 'telephone':
        value = telephone;
        break;
    }
    try {
      const response = await axios.put('https://api.sphereviewer.com.br/api/panel/user/update', { [field]: value }, config);
      setUser(response.data.user);
      setMessage(`Seu ${field} foi atualizado com sucesso!`);
    } catch (error) {
      console.error('Erro ao atualizar:', error);
      setMessage('Erro ao atualizar!');
    } finally {
      setLoadingField(null);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get('https://api.sphereviewer.com.br/api/panel/user', config);
      setUserData(response.data);
      setname(response.data.name);
      setlastname(response.data.lastname);
      settelephone(response.data.telephone);
    } catch (error) {
      console.error('Erro ao buscar dados do usuário:', error);
    }
  };

  const checkToken = async () => {
    try {
      const response = await axios.get('https://api.sphereviewer.com.br/api/panel/check-token', config);
      if (response.data.token) {
        setToken(response.data.token);
        setDomain(response.data.domain);
      }
    } catch (error) {
      console.error('Erro ao verificar o token:', error);
    }
  };

  const generateToken = async () => {
    try {
      const formData = new FormData();
      formData.append('domain', domain);
      const response = await axios.post('https://api.sphereviewer.com.br/api/panel/generate-token', formData, config);
      setToken(response.data.token);
      setMsgToken('Token gerado com sucesso!');
    } catch (error) {
      console.error('Erro ao gerar o token:', error);
    }
  };

  useEffect(() => {
    fetchUserData();
    checkToken();
  }, []);

  return (
    <div className={styles.MeusDados}>
      <Sidebar menu={menu} isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
      <div className={`${styles.content} ${menu ? styles.contentZ : styles.contentFull}`}>
        <h1 className={styles.title}>Meus Dados</h1>
        <br />
        {userData && (
          <>
            <h2>Dados Fixos</h2>
            <br />
            <p className={styles.field}>
              <div className={styles.inputGroup}>
                <FaEnvelope className={styles.icon} />
                <p>Email: &nbsp; </p>
                <span> {userData.email}</span>
              </div>
            </p>
            <p className={styles.field}>
              <div className={styles.inputGroup}>
                <FaStar className={styles.icon} />
                <p>Premium: &nbsp;</p>
                <span> {String(userData.premium) === "0" ? 'Não é Premium' : 'Premium active'}</span>
              </div>
            </p>
            <p className={styles.field}>
              <div className={styles.inputGroup}>
                <FaCalendarAlt className={styles.icon} />
                <p>Próxima data de expiracao da assinatura premium: &nbsp;</p>
                <span> {userData.premium_expiration === null || userData.premium_expiration === 'null' ? ' Sem Data' : userData.premium_expiration}</span>
              </div>
            </p>
            <br />
            <br />
            <h2>Dados Editáveis</h2>
            <br />
            <p className={styles.field}>
              <div className={styles.inputGroup}>
                <FaUser className={styles.icon} />
                <label>name:</label>
                <input
                  className={styles.inputField}
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  disabled={loadingField && loadingField !== 'name'}
                />
                <button
                  className={styles.editButton}
                  onClick={() => handleEdit('name')}
                  disabled={loadingField}
                >
                  {loadingField === 'name' ? <FaSpinner className={styles.spinner} /> : 'Editar'}
                </button>
              </div>
            </p>
            <p className={styles.field}>
              <div className={styles.inputGroup}>
                <FaUser className={styles.icon} />
                <label>lastname:</label>
                <input
                  className={styles.inputField}
                  value={lastname}
                  onChange={(e) => setlastname(e.target.value)}
                  disabled={loadingField && loadingField !== 'lastname'}
                />
                <button
                  className={styles.editButton}
                  onClick={() => handleEdit('lastname')}
                  disabled={loadingField}
                >
                  {loadingField === 'lastname' ? <FaSpinner className={styles.spinner} /> : 'Editar'}
                </button>
              </div>
            </p>
            <p className={styles.field}>
              <div className={styles.inputGroup}>
                <FaPhone className={styles.icon} />
                <label>telephone:</label>
                <InputMask
                  mask="(99) 99999-9999"
                  className={styles.inputField}
                  value={telephone}
                  onChange={(e) => settelephone(e.target.value)}
                  disabled={loadingField && loadingField !== 'telephone'}
                />
                <button
                  className={styles.editButton}
                  onClick={() => handleEdit('telephone')}
                  disabled={loadingField}
                >
                  {loadingField === 'telephone' ? <FaSpinner className={styles.spinner} /> : 'Editar'}
                </button>
              </div>
            </p>
            <br />
            <br />
            <br />
            <h2>Token de Instalação</h2>
            <h4 className={styles.atentionToken}>Atenção: se o domínio for atualizado, será necessário atualizar o token usado na instalação do script, pois o valor mudará.</h4>
            <p>O token de instalação é usado para integrar as fotos do seu painel no Sphere Viewer em tempo real em seu site.</p>
            <p>Para instalar o script, insira o token gerado abaixo na Tag do seu GTM ou no arquivo HTML do seu site.</p>
            <p>Para ver mais acesse o nosso <a href='/steps' title='passo a passo'>passo a passo</a> de instalação.</p>
            <br />
            <br />
            <p className={styles.field}>
              <div className={styles.inputGroup}>
                <FaGlobe className={styles.icon} />
                <label>Domínio:</label>
                <input
                  className={styles.inputField}
                  value={domain}
                  placeholder='Link do seu dominio com https e www. Ex: https://www.suaempresa.com.br'
                  onChange={(e) => setDomain(e.target.value)}
                />
                <button
                  className={styles.editButtonToken}
                  onClick={generateToken}
                >
                  {token ? 'Atualizar Token de Instalação' : 'Gerar Token de Instalação'}
                </button>
              </div>
            </p>
            <p className={styles.field}>
              <div className={styles.inputGroup}>
                <FaLock className={styles.icon} />
                <label>Token:</label>
                <input
                  className={styles.inputField}
                  value={token}
                  readOnly
                />
                <button
                  className={styles.copyButton}
                  onClick={() => navigator.clipboard.writeText(token)}
                >
                  <FaCopy />
                </button>
                <span className={styles.tokenMsg}>{msgToken}</span>
              </div>
            </p>
          </>
        )}
        {message && <p className={styles.message}>{message}</p>}
      </div>
    </div>
  );
}

export default MeusDados;