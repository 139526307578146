import React, { useState } from 'react';
import axios from 'axios';
import styles from './Upload.module.css'; // Import the CSS module

function Upload({ fetchUserPhotosByCategory, categories }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    category_id: '', // Novo atributo para selecionar a categoria
    limit_date: '',
    initial_date: '',
    link: '',
    active: false,
  });

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFormDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleUpload = async () => {
    try {
      if (!selectedFile) {
        setMessage('Selecione um arquivo para fazer o upload.');
        return;
      }

      // Verifique se os campos obrigatórios estão preenchidos
      if (
        formData.category_id === '' ||
        formData.limit_date === '' ||
        formData.initial_date === '' ||
        formData.link === ''
      ) {
        setMessage('Preencha todos os campos obrigatórios.');
        return;
      }

      formData.active = formData.active ? 1 : 0;

      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };

      const formDataToUpload = new FormData();
      formDataToUpload.append('image', selectedFile);

      // Anexe os campos de formulário à solicitação
      for (const key in formData) {
        formDataToUpload.append(key, formData[key]);
      }

      const response = await axios.post('https://api.sphereviewer.com.br/api/panel/upload', formDataToUpload, config);
      setMessage(response.data.message);
      setSelectedFile(null);
      fetchUserPhotosByCategory();
    } catch (error) {
      console.error('Erro ao fazer o upload:', error);
      setMessage('Erro ao fazer o upload.');
    }
  };

  return (
    <div className={styles.uploadContainer}>
      <h2>Upload de Foto</h2>
      <input type="file" onChange={handleFileChange} className={styles.fileInput} />
      <select
        name="category_id"
        value={formData.category_id}
        onChange={handleFormDataChange}
        className={styles.categorySelect}
      >
        <option value="">Selecione uma Categoria</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.category_name}
          </option>
        ))}
      </select>
      <input
        type="datetime-local"
        name="initial_date"
        placeholder="Data Inicial"
        value={formData.initial_date}
        onChange={handleFormDataChange}
        className={styles.input}
      />
      <input
        type="datetime-local"
        name="limit_date"
        placeholder="Data Limite"
        value={formData.limit_date}
        onChange={handleFormDataChange}
        className={styles.input}
      />
      <input
        type="text"
        name="link"
        placeholder="Link"
        value={formData.link}
        onChange={handleFormDataChange}
        className={styles.input}
      />
      <label>
        Exibir Foto
        <input
          type="checkbox"
          name="active"
          className={styles.activeCheckbox}
          checked={formData.active}
          onChange={handleFormDataChange}
        />
      </label>
      <button onClick={handleUpload} className={styles.button}>
        Upload
      </button>
      <p className={styles.message}>{message}</p>
    </div>
  );
}

export default Upload;