import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './about.module.css'; // Import your styles

const About = () => {
    return (
        <div className={styles.container}>
            <Helmet>
                <title>Sobre - Sphere Viewer</title>
                <meta name="description" content="Bem-vindo à Sphere Viewer, uma startup pioneira fundada em 2024. Nós nos especializamos em fornecer stories customizados para sites e lojas online, projetados para melhorar o marketing e a conversão do seu negócio. Com a nossa abordagem inovadora, oferecemos uma variedade de opções de stories que cactivem seu público-alvo, desde a personalização até a instalação prática. Na Sphere Viewer, estamos comprometidos em transformar sua presença digital em uma experiência envolvente e memorável para seus clientes. Junte-se a nós enquanto contamos a sua história de forma única e impactante." />
                <meta name="keywords" content="Sphere Viewer, stories, marketing, conversão, presença digital, experiência, clientes" />
                <meta property="og:title" content="Sobre - Sphere Viewer" />
                <meta property="og:description" content="Bem-vindo à Sphere Viewer, uma startup pioneira fundada em 2024. Nós nos especializamos em fornecer stories customizados para sites e lojas online, projetados para melhorar o marketing e a conversão do seu negócio. Com a nossa abordagem inovadora, oferecemos uma variedade de opções de stories que cactivem seu público-alvo, desde a personalização até a instalação prática. Na Sphere Viewer, estamos comprometidos em transformar sua presença digital em uma experiência envolvente e memorável para seus clientes. Junte-se a nós enquanto contamos a sua história de forma única e impactante." />
                <meta property="og:image" content="https://www.sphereviewer.com.br/static/media/logo.04dea2c302dc28cbd57d.png" />
                <meta property="og:url" content="https://www.sphereviewer.com.br/about" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <h1 className={styles.title}>Sobre Nós</h1>
            <p className={styles.description}>
                Bem-vindo à Sphere Viewer, uma startup pioneira fundada em 2024. Nós nos especializamos em fornecer stories customizados para sites e lojas online, projetados para melhorar o marketing e a conversão do seu negócio. Com a nossa abordagem inovadora, oferecemos uma variedade de opções de stories que cactivem seu público-alvo, desde a personalização até a instalação prática. Na Sphere Viewer, estamos comprometidos em transformar sua presença digital em uma experiência envolvente e memorável para seus clientes. Junte-se a nós enquanto contamos a sua história de forma única e impactante.
            </p>
        </div>
    );
};

export default About;