import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Helmet } from 'react-helmet';
import styles from './Home.module.css';
import StoryHomeVersion from '../Story/StoryHomeVersion';
import imgFuncionamento from '../../assets/imgs/funcionamento.jpg';


function Home() {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
  });

  // Dados simulados para o componente Story
  const userHome = { id: 1, name: 'Usuário Exemplo' };
  const categoriesHome = [
    { id: 1, category_name: 'name da sua Categoria' },
    { id: 2, category_name: 'name da sua Categoria' },
    { id: 3, category_name: 'name da sua Categoria' }
  ];
  const photosHome = {
    1: [
      { id: 1, image: '1.PNG', active: 1, link: '/photos/1/1.PNG' },
      { id: 2, image: '2.PNG', active: 1, link: '/photos/1/2.PNG' },
      { id: 3, image: '3.PNG', active: 1, link: '/photos/1/3.PNG' },
      { id: 4, image: '4.PNG', active: 1, link: '/photos/1/4.PNG' },
    ],
    2: [
      { id: 5, image: '5.PNG', active: 1, link: '/photos/1/5.PNG' },
      { id: 6, image: '6.PNG', active: 1, link: '/photos/1/6.PNG' },
      { id: 7, image: '7.PNG', active: 1, link: '/photos/1/7.PNG' },
      { id: 8, image: '8.PNG', active: 1, link: '/photos/1/8.PNG' },
    ],
    3: [
      { id: 9, image: '9.PNG', active: 1, link: '/photos/1/9.PNG' },
      { id: 10, image: '10.PNG', active: 1, link: '/photos/1/10.PNG' },
      { id: 11, image: '11.PNG', active: 1, link: '/photos/1/11.PNG' },
      { id: 12, image: '12.PNG', active: 1, link: '/photos/1/12.PNG' },
    ]
  };
  const categoryDetailsHome = {
    1: { active: 1, image_id: 1 },
    2: { active: 1, image_id: 5 },
    3: { active: 1, image_id: 9 },
  };





  return (
    <div className={styles.container}>
      <Helmet>
        <title>Sphere Viewer - Visualizador de Stories B2B</title>
        <meta name="description" content="Sphere Viewer é um visualizador de stories customizado para ser usado B2B." />
        <meta name="keywords" content="Sphere Viewer, Stories, Visualizador, B2B, Reels, GTM" />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Felipe Lourenzi" />
        <meta property="og:title" content="SphereViewer - Visualizador de Stories B2B" />
        <meta property="og:description" content="SphereViewer é um visualizador de stories customizado para ser usado B2B." />
        <meta property="og:image" content="https://www.sphereviewer.com.br/static/media/logo.04dea2c302dc28cbd57d.png" />
        <meta property="og:url" content="https://www.sphereviewer.com.br/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <animated.div style={fadeIn}>
        <h1 className={styles.title}>Bem-vindo ao Sphere Viewer</h1>
        <p className={styles.description}><b>Tenha uma galeria de Stories e Reels própria para o seu site:</b></p>
        <StoryHomeVersion
          userHome={userHome}
          categoryDetailsHome={categoryDetailsHome}
          photosHome={photosHome}
          categoriesHome={categoriesHome}
        />
        <p className={styles.description}><b>Funcionamento:</b></p>
        <img src={imgFuncionamento} className={styles.imgFuncionamento} alt="funcionamento" title='funcionamento'></img>
        <p className={styles.description}><b>Customize e Gerencie da sua forma</b></p>
        <ul className={styles.bulletPoints}>
          <li>O Sphere Viewer é uma ferramenta poderosa que permite personalizar e gerenciar sua própria galeria de Stories em seu site ou ecommerce.</li>
          <li>Com o Sphere Viewer, você pode aumentar a conversão do seu site ou loja, adicionando links internos e externos aos seus Stories para aumentar sua conversão e navegação.</li>
          <li>Vincule seus Stories a links de suas redes sociais, como Instagram, Facebook e Twitter e melhore o fluxo promocional do seu negócio.</li>
        </ul>
        <p className={styles.description}>Adquira o <b>Sphere Viewer</b> hoje e descubra como ele pode <b>transformar a maneira como você compartilha conteúdo com seu público!</b></p>
        <p className={styles.description}>Fácil instalação via <b>tag GTM</b> ou em seu código.</p>
      </animated.div>
    </div>
  );
}

export default Home;