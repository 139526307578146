import React, { useState } from 'react';
import axios from 'axios';
import styles from './Register.module.css'; // Import the CSS module
import { FaUser, FaLock, FaEnvelope, FaPhone, FaUserCircle, FaCreditCard } from 'react-icons/fa';

function Register() {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [lastname, setLastname] = useState('');
    const [telephone, setTelephone] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState({});
    const [view, setView] = useState('register'); // Adicione este estado

    const validateFields = () => {
        const newError = {};
        if (!name) newError.name = true;
        if (!password) newError.password = true;
        if (!email) newError.email = true;
        if (!lastname) newError.lastname = true;
        if (!telephone) newError.telephone = true;

        setError(newError);

        return Object.keys(newError).length === 0;
    };

    const handleRegister = async () => {
        if (!validateFields()) {
            setMessage('Todos os campos precisam estar preenchidos');
            return;
        }

        try {
            const response = await axios.post('https://api.sphereviewer.com.br/api/panel/create-user', { name, password, email, lastname, telephone });
            setMessage(response.data.message);
            setName('');
            setPassword('');
            setEmail('');
            setLastname('');
            setTelephone('');
            setError({});
            setView('payment'); // Mude a view para 'payment' após o registro
        } catch (error) {
            console.error('Erro durante o registro:', error);
            setMessage(error.response.data.message);
        }
    };

    const handlePix = () => {
        setView('pix');
    };

    return (
        <div className="container">
            {view === 'register' && (
                <div className={styles.registerWrapper}>
                    <h1>Cadastre-se</h1>
                    <div className={styles.inputGroup}>
                        <FaUser className={styles.icon} />
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="name"
                            required
                            className={error.name ? styles.error : ''}
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <FaLock className={styles.icon} />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Senha"
                            required
                            className={error.password ? styles.error : ''}
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <FaEnvelope className={styles.icon} />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                            className={error.email ? styles.error : ''}
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <FaUserCircle className={styles.icon} />
                        <input
                            type="text"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            placeholder="lastname"
                            required
                            className={error.lastname ? styles.error : ''}
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <FaPhone className={styles.icon} />
                        <input
                            type="text"
                            value={telephone}
                            onChange={(e) => setTelephone(e.target.value)}
                            placeholder="telephone"
                            required
                            className={error.telephone ? styles.error : ''}
                        />
                    </div>
                    <button onClick={handleRegister} className={styles.button}>
                        <FaCreditCard className={styles.icon} />  Ir Para o Pagamento
                    </button>
                    {message && <p className={styles.message}>{message}</p>}
                </div>
            )}

            {view === 'payment' && (
                <div className={styles.registerWrapper}>
                    <h1>Escolha uma opção de pagamento</h1>
                    <button onClick={handlePix} className={styles.button}>
                        Pix
                    </button>
                    <button disabled style={{backgroundColor: "#a1a1a1"}}className={styles.button}>
                        PayPal - Em breve
                    </button>
                </div>
            )}

            {view === 'pix' && (
                <div className={styles.registerWrapper}>
                    <h1>Informações de pagamento</h1>
                    <p><b>chave Pix: atendimento@sphereviewer.com.br</b></p>
                    <p>Seu pré-cadastro já foi aprovado, faça o pagamento para poder realizar o login e transformar sua assinatura active.</p>
                    <p>O tempo médio para a activeção da assinatura após o pagamento do pix é de no máximo 10 minutos.</p>
                    <p>Após realizar o pagamento inicial de R$100 para a assinatura, lembre-se de que uma taxa será cobrada novamente no mês seguinte para que você possa continuar a usufruir das funcionalidades do Sphere Viewer.</p>
                </div>
            )}
        </div>
    );
}

export default Register;