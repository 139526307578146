import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { FaTrash, FaCog, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Sidebar from '../Common/Sidebar/sidebar';
import styles from './UserPanel.module.css';
import Upload from '../Upload/upload';
import Modal from 'react-modal';
import Story from '../Story/story';

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

const UserPanel = () => {
  const [user, setUser] = useState(null);
  const [menu, setMenu] = useState(true);
  const [photos, setPhotos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [photoOrder, setPhotoOrder] = useState([]);
  const [currentPhoto, setCurrentPhoto] = useState(null);

  const isLoggedIn = localStorage.getItem('token');
  const location = useLocation();

  useEffect(() => {
    fetchUserCategories();
  }, []);

  useEffect(() => {
    fetchUserPhotosByCategory();
  }, [categories]);

  useEffect(() => {
    fetchUserData();
  }, []);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${isLoggedIn}`,
    },
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get('https://api.sphereviewer.com.br/api/panel/user', config);
      setUser(response.data);
    } catch (error) {
      console.error('Erro ao buscar dados do usuário:', error);
    }
  };

  useEffect(() => {
    if (selectedCoverImage !== null && activeCategory !== null) {
      setCategoryDetails(prevState => ({
        ...prevState,
        [activeCategory]: {
          ...prevState[activeCategory],
          image_id: selectedCoverImage
        }
      }));
    }
  }, [selectedCoverImage]);

  const handleEditPhoto = (photo) => {
    setCurrentPhoto(photo);
    setIsModalOpen2(true);
  };

  const handleLogout = async () => {
    try {
      await axios.post('https://api.sphereviewer.com.br/api/panel/logout', {}, config);
      localStorage.removeItem('token');
      if (location.pathname !== '/') {
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const fetchUserCategories = async () => {
    try {
      const response = await axios.get('https://api.sphereviewer.com.br/api/panel/categories', config);
      setCategories(response.data);
    } catch (error) {
      console.error('Erro ao buscar as categorias do usuário:', error);
    }
  };

  const movePhoto = (index, direction) => {
    let newPhotoOrder = [...photoOrder];
    const photo = newPhotoOrder[index];
    newPhotoOrder.splice(index, 1); // remove the photo at the current index

    if (direction === 'left') {
      const targetIndex = index === 0 ? newPhotoOrder.length : index - 1;
      newPhotoOrder.splice(targetIndex, 0, photo); // insert at the target index
    } else {
      const targetIndex = index === newPhotoOrder.length ? 0 : index + 1;
      newPhotoOrder.splice(targetIndex, 0, photo); // insert at the target index
    }

    // Update the position of each photo
    newPhotoOrder = newPhotoOrder.map((photo, i) => {
      photo.position = i;
      return photo;
    });

    setPhotoOrder(newPhotoOrder);
  };

  const saveNewOrder = async () => {
    try {
      await axios.put('https://api.sphereviewer.com.br/api/panel/update-photo-position', { photos: photoOrder }, config);
    } catch (error) {
      console.error('Error updating photo position:', error);
    }
  };

  const fetchUserPhotosByCategory = async () => {
    const photosByCategory = {};

    try {
      for (const category of categories) {
        const categoryId = category.id;
        const response = await axios.get(`https://api.sphereviewer.com.br/api/panel/photos/${categoryId}`, config);
        photosByCategory[categoryId] = response.data;
      }

      setPhotos(photosByCategory);
    } catch (error) {
      console.error('Error fetching user photos by category:', error);
    }
  };

  const handleDeletePhoto = async (photoId) => {
    try {
      await axios.delete(`https://api.sphereviewer.com.br/api/panel/photos/${photoId}`, config);
      fetchUserPhotosByCategory();
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  const handleEditCategory = (categoryId) => {
    setActiveCategory(categoryId);
    setPhotoOrder(photos[activeCategory]);
    setIsModalOpen(true);
  };

  const handleSavePhotoDetails = async (photoId, details) => {
    try {
      // Converta as datas para o formato correto
      details.limit_date = new Date(details.limit_date).toISOString().slice(0, 19).replace('T', ' ');
      details.initial_date = new Date(details.initial_date).toISOString().slice(0, 19).replace('T', ' ');

      const response = await axios.put(`https://api.sphereviewer.com.br/api/panel/photos/${photoId}`, details, config);

      if (response.status === 200) {
        alert('Foto atualizada com sucesso');
      } else {
        alert('Erro ao atualizar a foto');
      }
    } catch (error) {
      console.error('Erro ao atualizar a foto:', error);
      alert('Erro ao atualizar a foto');
    }
  };

  const fetchCategoryDetails = async (categoryId) => {
    try {
      const response = await axios.get(`https://api.sphereviewer.com.br/api/panel/category-details/${categoryId}`, config);
      const categoryDetails = response.data;

      // Fetch the cover image if it exists
      if (categoryDetails.image_id) {
        const coverImageResponse = await axios.get(`https://api.sphereviewer.com.br/api/panel/photo/${categoryDetails.image_id}`, config);
        categoryDetails.cover_image = coverImageResponse.data;
      }

      setCategoryDetails(prevState => ({ ...prevState, [categoryId]: categoryDetails }));
    } catch (error) {
      console.error('Erro ao buscar os detalhes da categoria:', error);
    }
  };

  useEffect(() => {
    categories.forEach(category => fetchCategoryDetails(category.id));
  }, [categories]);

  useEffect(() => {
    if (photos[activeCategory]) {
      setPhotoOrder(JSON.parse(JSON.stringify(photos[activeCategory])));
    }
  }, [activeCategory, photos]);

  useEffect(() => {
    if (activeCategory !== null) {
      const coverImage = categoryDetails[activeCategory]?.cover_image;
      setSelectedCoverImage(coverImage ? coverImage[0] : null);
    }
  }, [activeCategory]);

  const handleSaveCategoryDetails = async (categoryId, details) => {
    try {
      await axios.put(`https://api.sphereviewer.com.br/api/panel/category-details/${categoryId}`, details, config);
      fetchUserPhotosByCategory();
      fetchUserCategories(); // Fetch categories again after saving category details
    } catch (error) {
      console.error('Erro ao salvar os detalhes da categoria:', error);
    }
  };

  return (
    <div className={styles.userPanel}>
      <Sidebar menu={menu} isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
      <div className={`${styles.content} ${menu ? styles.contentZ : styles.contentFull}`}>
        <div className={styles.uploadSection}>
          <Upload fetchUserPhotosByCategory={fetchUserPhotosByCategory} categories={categories} />
        </div>
        <div className={styles.storySection}>
          <h2>Preview Stories</h2>
          <Story user={user} categoryDetails={categoryDetails} photos={photos} categories={categories} />
        </div>
        <div className={styles.photoSection}>
          {categories.map((category) => (
            <div key={category.id} className={styles.categorySection}>
              <span className={styles.catLabel}>Categoria:</span>
              <h3>{category.category_name}</h3>
              <div className={styles.categoryActions}>
                <div>
                  Capa Atual:
                  <div className={styles.coverImage}>
                    {categoryDetails[category.id]?.cover_image &&
                      <img src={`https://api.sphereviewer.com.br/api/photos/${user.id}/${categoryDetails[category.id]?.cover_image?.image}`} alt="Cover" />
                    }
                  </div>
                </div>
                <div>Status Categoria: {categoryDetails[category.id]?.active ? 'Ativa' : 'Inativa'}</div>
                <button className={styles.saveButton} onClick={() => handleEditCategory(category.id)}>
                  <FaCog /> Editar
                </button>
              </div>
              <div className={styles.photoContainerOut}>
                {photos[category.id] && [...photos[category.id]].sort((a, b) => a.position - b.position).map((photo) => (
                  <div key={photo.id} className={styles.photoItem}>
                    {photo.active === 1 && <div className={styles.flag}>Exibir</div>}
                    <img src={`https://api.sphereviewer.com.br/api/photos/${photo.user_id}/${photo.image}`} alt={photo.title} />
                    <div className={styles.buttonContainer}>
                      <button className={styles.deleteButton} onClick={() => handleDeletePhoto(photo.id)}>
                        <FaTrash /> Deletar
                      </button>
                      <button className={styles.editPhotoButton} onClick={() => handleEditPhoto(photo)}>
                        <FaCog />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {isModalOpen2 && currentPhoto && (
        <Modal
          isOpen={isModalOpen2}
          onRequestClose={() => { setIsModalOpen2(false) }}
          contentLabel="Editar Foto"
          className={styles.modalContent}
        >
          <h2>Editar Foto</h2>
          <input
            className={styles.input}
            type="datetime-local"
            value={currentPhoto.limit_date ? new Date(currentPhoto.limit_date).toISOString().substring(0, 16) : ''}
            onChange={(e) => setCurrentPhoto({ ...currentPhoto, limit_date: e.target.value })}
          />
          <input
            className={styles.input}
            type="datetime-local"
            value={currentPhoto.initial_date ? new Date(currentPhoto.initial_date).toISOString().substring(0, 16) : ''}
            onChange={(e) => setCurrentPhoto({ ...currentPhoto, initial_date: e.target.value })}
          />
          <input className={styles.input} type="text" value={currentPhoto.link} onChange={(e) => setCurrentPhoto({ ...currentPhoto, link: e.target.value })} />

          <label>
            Exibir Foto:
            <input
              className={styles.activeCheckbox}
              type="checkbox"
              checked={currentPhoto.active}
              onChange={(e) => setCurrentPhoto({ ...currentPhoto, active: e.target.checked })}
            />
          </label>

          <button className={styles.saveButton} onClick={() => handleSavePhotoDetails(currentPhoto.id, currentPhoto)}>Salvar</button>
        </Modal>
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => { setIsModalOpen(false); setPhotoOrder([]); }}
        contentLabel="Edit Category Modal"
        className={styles.modalContent}
      >
        <h2>Editar Categoria</h2>
        <label>
          Imagem da Capa:
          <div className={styles.coverImage}>
            {selectedCoverImage && <img src={`https://api.sphereviewer.com.br/api/photos/${user.id}/${selectedCoverImage.image}`} alt="Cover" />}
          </div>
        </label>
        <label>
          Categoria ativa:
          <input
            type="checkbox"
            checked={categoryDetails[activeCategory]?.active || false}
            onChange={(e) => setCategoryDetails(prevState => ({ ...prevState, [activeCategory]: { ...prevState[activeCategory], active: e.target.checked } }))}
          />
        </label>
        <button className={styles.saveButton} onClick={async () => {
          try {
            const details = { ...categoryDetails[activeCategory], image_id: selectedCoverImage.id };
            delete details.cover_image;
            await handleSaveCategoryDetails(activeCategory, details);
            await saveNewOrder();
          } catch (error) {
            console.error('Error executing functions:', error);
          }
        }}>Salvar</button>
        <div className={styles.photoContainer}>
          {photoOrder && [...photoOrder].sort((a, b) => a.position - b.position).map((photo, index) => (
            <div key={photo.id} className={styles.photoItem}>
              <FaArrowLeft className={styles.arrowLeft} onClick={() => movePhoto(index, 'left')} />
              <FaArrowRight className={styles.arrowRight} onClick={() => movePhoto(index, 'right')} />
              {photo.active === 1 && <div className={styles.flag}>Exibir</div>}
              <img src={`https://api.sphereviewer.com.br/api/photos/${photo.user_id}/${photo.image}`} alt={photo.title} onClick={() => setSelectedCoverImage(photo)} />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default UserPanel;